import React, { useState } from 'react'
import Image from 'next/image'
import type { Session } from 'next-auth'

import ExternalLink from 'components/Link/ExternalLink'

import style from './GuestHeaderMenu.module.scss'

import CaretIcon from 'assets/icons/icon-caret.svg'

export type GuestHeaderMenuProps = {
  isMobile?: boolean
  children: React.ReactNode
  guestHeaderMenuRef?: React.RefObject<HTMLDivElement>
  session: Session | null
}

const GuestHeaderMenu: React.FC<GuestHeaderMenuProps> = ({
  isMobile,
  children,
  guestHeaderMenuRef,
  session,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false)
  const [showFadeIn, setShowFadeIn] = useState(false)

  return session ? (
    <div
      className={`${style.menuBody} ${isMobile ? style.isMobile : ''} ${
        isMobile && !isCollapsed ? style.expanded : ''
      } `}
      ref={guestHeaderMenuRef}
    >
      <div
        aria-hidden="true"
        className={`${style.menuMain}`}
        onClick={() => {
          if (isMobile) {
            setShowFadeIn(true)
            setIsCollapsed(!isCollapsed)
          }
        }}
      >
        <div className={`${style.names} ${isMobile ? style.mobile : ''}`}>
          <h5>Guest Account</h5>
          <p>{session.user.name}</p>
        </div>

        {isMobile && (
          <>
            <div className={style.userAvatarImgContainer}>
              <Image
                alt="Guest Avatar"
                className={style.userAvatarImg}
                height={40}
                src={session.user.image}
                width={40}
              />
            </div>

            <CaretIcon
              className={`${style.caret} ${!isCollapsed ? style.focused : ''}`}
              title={`Caret ${isCollapsed ? 'Down' : 'Up'}`}
            />
          </>
        )}
      </div>

      <div
        className={`${style.userLinks} ${
          showFadeIn && isMobile && !isCollapsed ? style.fadeIn : ''
        }`}
        data-testid="GuestHeaderMenuExpandablePanel"
      >
        <ExternalLink
          aria-labelledby="go to guest account"
          href={session.sfdc_community_url}
          rel="noopener noreferrer"
          target="_blank"
        >
          Go to Account
        </ExternalLink>
        {children}
      </div>
    </div>
  ) : null
}

export default GuestHeaderMenu
