// no-static-element-interactions gives a warning for role set via ternary
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useMemo, useState } from 'react'
import Image from 'next/image'
import { useDispatch } from 'react-redux'

import { useSelect } from 'store/index'

import { setShowPreferencesModal } from 'reducers/uiState'

import CustomLink from 'components/Link/CustomLink'
import ExternalLink from 'components/Link/ExternalLink'

import style from './Footer.module.scss'

import { LargeTabletBreakpoint } from 'config/Breakpoints'

import Logo from 'assets/logos/evolve-e-logo.svg'

type FooterProps = {
  footerData: any
}

const Footer: React.FC<FooterProps> = ({ footerData }) => {
  const [jsIsEnabled, setJsIsEnabled] = useState(false)
  const width = useSelect((state) => state.uiState.width)
  const isDesktop = useMemo(() => width >= LargeTabletBreakpoint, [width])
  const [activeFooterCategory, setActiveFooterCategory] = useState<
    number | null
  >(null)

  const appDispatch = useDispatch()

  useEffect(() => {
    setJsIsEnabled(true)
  }, [])

  const buildFooterCategoryLinks = (group: string) => {
    const links: JSX.Element[] = []
    if ('menus' in footerData) {
      for (let i = 0; i < footerData.menus[group].length; i++) {
        const link = footerData.menus[group][i]

        /* TODO:
          Elementor Pages don't work properly using soft navigation, some widgets don't render correctly,
          that's why a hard navigation (using `a` instead of Link) is required. For the future
          Elementor + Elementor Pro should be updated and this script should be updated
          /public/assets/scripts/elementor/elementor-widgets.min.js
        */
        links.push(
          <ExternalLink
            aria-label={`link ${link.title}${
              i === 0 ? `, list ${footerData.menus[group].length} items` : ''
            }`}
            className={`${style.footerLink} ${style.linkText} footer_group_link btn-cta`}
            href={link.url}
            key={i}
          >
            {link.title}
          </ExternalLink>,
        )
      }
    }
    return links
  }

  const setSiteLinks = (jsIsEnabled) => {
    const siteLinks: JSX.Element[] = []
    if ('menus' in footerData) {
      for (let i = 0; i < footerData.menus.sitelinks.length; i++) {
        const link = footerData.menus.sitelinks[i]
        const isCookieSettingsUrl = link.url === '/cookiesettings'

        if (!jsIsEnabled && isCookieSettingsUrl) break

        siteLinks.push(
          <CustomLink
            aria-label={`link, navigate to ${link.title}`}
            className={`${style.footerBottomLink} footer_site_link btn-cta`}
            href={link.url}
            key={i}
            onClick={(e) => {
              if (!isCookieSettingsUrl) return
              e.preventDefault()
              appDispatch(setShowPreferencesModal(true))
            }}
            prefetch={false}
          >
            <span className={style.linkText}>{link.title}</span>
          </CustomLink>,
        )
      }
    }
    return siteLinks
  }

  const setSocialLinks = () => {
    const socialLinks: JSX.Element[] = []
    if ('options' in footerData) {
      for (let i = 0; i < footerData.options.footer_social_links.length; i++) {
        const link = footerData.options.footer_social_links[i]

        socialLinks.push(
          <CustomLink
            aria-label={`link, navigate to ${link.title}`}
            className={`${style.footerSocial} footer_social_link btn-cta`}
            href={link.link}
            key={i}
            prefetch={false}
            rel="noopener noreferrer"
          >
            {isDesktop ? (
              <Image
                alt={link.title}
                height={24}
                src={link.icon.url}
                width={24}
              />
            ) : (
              <Image
                alt={link.title}
                height={32}
                src={link.icon.url}
                width={32}
              />
            )}
          </CustomLink>,
        )
      }
    }
    return socialLinks
  }

  const mobileClick = (val: number) => {
    if (activeFooterCategory !== val) {
      setActiveFooterCategory(val)
    } else {
      setActiveFooterCategory(null)
    }
  }

  return (
    <footer className={style.appFooter}>
      <CustomLink
        aria-label="navigate to evolve.com"
        className={`btn-cta ${style.footerLogo}`}
        href="/"
        id="footer_evolve_logo_link"
        prefetch={false}
      >
        {isDesktop ? <Logo width={68} /> : <Logo width={48} />}
      </CustomLink>
      <div className={style.footerMainLinks}>
        <div className={style.footerCategory}>
          <span
            className={`${style.footerCategoryTitle} ${
              !isDesktop && activeFooterCategory === 1
                ? style.footerCategoryTitleActive
                : ''
            }`}
            onClick={() => !isDesktop && mobileClick(1)}
            onKeyDown={(e) => {
              if (!isDesktop && e.key === 'Enter') {
                mobileClick(1)
              }
            }}
            role={!isDesktop ? 'button' : 'heading'}
            tabIndex={!isDesktop ? 0 : undefined}
          >
            For Owners
          </span>
          <div
            className={`${style.footerLinkWrapper}${
              activeFooterCategory === 1
                ? ' ' + style.footerLinkWrapperActive
                : ''
            }`}
          >
            {footerData && buildFooterCategoryLinks('homeowners')}
          </div>
        </div>
        <div className={style.footerCategory}>
          <span
            className={`${style.footerCategoryTitle} ${
              !isDesktop && activeFooterCategory === 2
                ? style.footerCategoryTitleActive
                : ''
            }`}
            onClick={() => !isDesktop && mobileClick(2)}
            onKeyDown={(e) => {
              if (!isDesktop && e.key === 'Enter') {
                mobileClick(2)
              }
            }}
            role={!isDesktop ? 'button' : 'heading'}
            tabIndex={!isDesktop ? 0 : undefined}
          >
            For Guests
          </span>
          <div
            className={`${style.footerLinkWrapper}${
              activeFooterCategory === 2
                ? ' ' + style.footerLinkWrapperActive
                : ''
            }`}
          >
            {footerData && buildFooterCategoryLinks('guests')}
          </div>
        </div>
        <div className={style.footerCategory}>
          <span
            className={`${style.footerCategoryTitle} ${
              !isDesktop && activeFooterCategory === 3
                ? style.footerCategoryTitleActive
                : ''
            }`}
            onClick={() => !isDesktop && mobileClick(3)}
            onKeyDown={(e) => {
              if (!isDesktop && e.key === 'Enter') {
                mobileClick(3)
              }
            }}
            role={!isDesktop ? 'button' : 'heading'}
            tabIndex={!isDesktop ? 0 : undefined}
          >
            Company
          </span>
          <div
            className={`${style.footerLinkWrapper}${
              activeFooterCategory === 3
                ? ' ' + style.footerLinkWrapperActive
                : ''
            }`}
          >
            {footerData && buildFooterCategoryLinks('company')}
          </div>
        </div>
        <div className={style.footerCategory}>
          <span
            className={`${style.footerCategoryTitle} ${
              !isDesktop && activeFooterCategory === 4
                ? style.footerCategoryTitleActive
                : ''
            }`}
            onClick={() => !isDesktop && mobileClick(4)}
            onKeyDown={(e) => {
              if (!isDesktop && e.key === 'Enter') {
                mobileClick(4)
              }
            }}
            role={!isDesktop ? 'button' : 'heading'}
            tabIndex={!isDesktop ? 0 : undefined}
          >
            Help
          </span>
          <div
            className={`${style.footerLinkWrapper}${
              activeFooterCategory === 4
                ? ' ' + style.footerLinkWrapperActive
                : ''
            }`}
          >
            {footerData && buildFooterCategoryLinks('help')}
          </div>
        </div>
      </div>
      {isDesktop && <hr className={style.divider} />}
      <div className={style.socialLinks}>{footerData && setSocialLinks()}</div>
      <span className={`${style.footerBottomLink} ${style.copyright}`}>
        {footerData &&
        'options' in footerData &&
        footerData.options.footer_copyright
          ? footerData.options.footer_copyright
          : '© Evolve Vacation Rental. All Rights Reserved.'}
      </span>
      <div className={style.siteLinks}>
        {footerData && setSiteLinks(jsIsEnabled)}
      </div>
    </footer>
  )
}

export default React.memo(Footer)
