import { useState, useEffect } from 'react'
import throttle from 'lodash/throttle'

function getScrollPosition() {
  if (!process.browser) {
    return { x: 0, y: 0 }
  }

  const { scrollX: x, scrollY: y } = window

  return {
    x,
    y,
  }
}

export default function useScrollPosition() {
  const [position, setPosition] = useState(getScrollPosition())

  useEffect(() => {
    const handleScroll = throttle(() => {
      setPosition(getScrollPosition())
    }, 100)

    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return position
}
