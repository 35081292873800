import React, {
  MouseEventHandler,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { useSession } from 'next-auth/react'
import Image from 'next/image'
import Script from 'next/script'
import classNames from 'classnames'

import { useSelect } from 'store/index'

import { BannerContext } from 'context/BannerContext'

import useScrollPosition from 'hooks/useScrollPosition'
import useAugmentedRouter from 'hooks/useAugmentedRouter'

import CustomLink from 'components/Link/CustomLink'
import ExternalLink from 'components/Link/ExternalLink'
import GuestLogin from 'components/Guest/GuestLogin'
import GuestHeaderMenu from 'components/Guest/GuestHeaderMenu'
import SegmentConsentManager from 'components/SegmentCookieConsent/SegmentConsentManager'

import style from './Header.module.scss'

import headerMenu from 'config/headerMenu'

import NotificationDot from 'assets/icons/icon-notificationDotRed.svg'
import Logo from 'assets/logos/evolve-logo.svg'

export type HeaderProps = {
  navHidden?: boolean
  needHelpLink?: boolean
  fullNavWidth?: number
  step?: number
  transparent?: boolean
  page?: string
  children?: React.ReactNode
}

const Header: React.FC<HeaderProps> = ({
  navHidden,
  needHelpLink,
  children,
  fullNavWidth = 1025,
  step,
  transparent,
  page,
}) => {
  const router = useAugmentedRouter()
  const { data: session } = useSession()
  const [mobileMenuActive, setMobileMenuActive] = useState(false)
  const [dropdownActive, setDropdownActive] = useState(false)
  const [userMenuActive, setUserMenuActive] = useState(false)
  const [sticky, setSticky] = useState(false)

  const { setBannerEl } = useContext(BannerContext)

  const width = useSelect((state) => state.uiState.width)
  const activeView = useSelect((state) => state.uiState.activeView)
  const showFavoriteNotification = useSelect(
    (state) => state.favorites.showFavoriteNotification,
  )
  const favoritesPageViewed = useSelect(
    (state) => state.favorites.favoritesPageViewed,
  )
  const isFilterBackgroundOpen = useSelect(
    ({ uiState }) => uiState.desktopFilterOpen || uiState.mobileFiltersOpen,
  )
  const showFavoritesBadge = showFavoriteNotification && !favoritesPageViewed

  const { y } = useScrollPosition()

  const dropdown = useRef<HTMLDivElement>(null)
  const mobileNavRef = useRef<HTMLElement>(null)
  const guestHeaderMenuRef = useRef<HTMLDivElement>(null)
  const userToggleRef = useRef<HTMLButtonElement>(null)

  const handleMobileMenuToggle = useCallback(() => {
    if (mobileNavRef.current) {
      if (mobileMenuActive) {
        enableBodyScroll(mobileNavRef.current)
      } else {
        disableBodyScroll(mobileNavRef.current)
      }
    }

    setTimeout(() => {
      setMobileMenuActive(!mobileMenuActive)
    })
  }, [mobileMenuActive])

  const handleLinkClick: MouseEventHandler<HTMLAnchorElement> = useCallback(
    (e) => {
      if (isFilterBackgroundOpen) {
        e.preventDefault()
      }
      if (width < fullNavWidth) {
        setMobileMenuActive(false)
        mobileNavRef.current && enableBodyScroll(mobileNavRef.current)
      }
    },
    [width, fullNavWidth, isFilterBackgroundOpen],
  )

  const handleUserMenuClick = useCallback(() => {
    setUserMenuActive((userMenuActive) => !userMenuActive)
  }, [])

  useEffect(() => {
    const handleOutsideClick = (e: any) => {
      if (dropdown.current && !dropdown.current.contains(e.target)) {
        setDropdownActive(false)
      }
      // User menu dropdown
      if (
        guestHeaderMenuRef.current &&
        !guestHeaderMenuRef.current.contains(e.target as Node) &&
        userToggleRef.current &&
        !userToggleRef.current.contains(e.target as Node)
      ) {
        setUserMenuActive(false)
      }
    }

    document.addEventListener('click', handleOutsideClick)

    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [])

  useEffect(() => {
    if (!router.pathname?.includes('/vacation-rentals/')) {
      if (y > 0) {
        setSticky(true)
      } else {
        setSticky(false)
      }
    }
  }, [router.pathname, y])

  const ownerLogin = (
    <ExternalLink
      className={`header${mobileMenuActive ? '_mobile' : ''}_account_link ${
        mobileMenuActive ? '' : style.headerLink
      } btn-cta`}
      href={'https://owner.evolve.com/login'}
      onClick={handleLinkClick}
      rel="noopener noreferrer"
      target="_blank"
    >
      Owner Login
    </ExternalLink>
  )

  const guestLoginComponent = (
    <GuestLogin
      className={`header${mobileMenuActive ? '_mobile' : ''}_account_link ${
        mobileMenuActive ? '' : style.headerLink
      } btn-cta`}
      displayText={{
        isLoggedIn: 'Log Out',
        isLoggedOut: 'Guest Login',
      }}
      id="header_guest_auth"
      onLogout={() => {
        setDropdownActive(false)
        setMobileMenuActive(false)
      }}
    />
  )

  return (
    <>
      <SegmentConsentManager.Advertising>
        <Script
          dangerouslySetInnerHTML={{
            __html: `(function(a,b,c,d,e,f,g){e['ire_o']=c;e[c]=e[c]||function(){(e[c].a=e[c].a||[]).push(arguments)};f=d.createElement(b);g=d.getElementsByTagName(b)[0];f.async=1;f.src=a;f.onload=window.ire('identify',{customerId:'',customerEmail: '',});g.parentNode.insertBefore(f,g);})('https://utt.impactcdn.com/A2972770-ab04-4cb4-a9f0-df6f0aceea841.js','script','ire',document,window);`,
          }}
          id="impact"
          type="text/javascript"
        />
      </SegmentConsentManager.Advertising>
      <div className={style.bannerContainer} ref={setBannerEl} />
      <header
        className={classNames(style.header, {
          [style.mobileMenuActive]: mobileMenuActive,
          [style.headerSticky]: sticky,
          [style.headerHidden]: activeView === 'map',
          [style.transparent]: transparent,
          [style.searchPage]: page === 'search',
          [style.favoritesPage]: page === 'favorites',
          [style.listingPage]: page === 'listing',
        })}
      >
        <nav
          className={classNames({
            [style.desktopNav]: width >= fullNavWidth,
            [style.mobileNav]: width < fullNavWidth,
          })}
          ref={width < fullNavWidth ? mobileNavRef : undefined}
        >
          <div className={width >= fullNavWidth ? style.navLinksContainer : ''}>
            <div
              className={`${style.logoContainer} ${
                page === 'search' ? style.searchPage : ''
              }`}
            >
              <CustomLink
                className={`btn-cta ${width < fullNavWidth && style.mobileCta}`}
                href="/"
                id={
                  width < fullNavWidth
                    ? 'header_evolve_logo_mobile_link'
                    : 'header_evolve_logo_link'
                }
                onClick={handleLinkClick}
                prefetch={false}
              >
                <Logo />

                <span>Evolve Vacation Rental</span>
              </CustomLink>
            </div>
            <div
              className={`${style.searchFields} ${
                page === 'search' ? style.searchPage : ''
              }`}
            >
              {children}
            </div>
            {step && (
              <div className={style.stepTracker}>
                {step !== 3 ? `Step ${step} of 2` : 'Confirmation'}
              </div>
            )}
            {needHelpLink && width >= fullNavWidth && (
              <ExternalLink
                className={`btn-noStyle ${style.needHelpBtn} btn-cta`}
                href="https://help.evolvevacationrental.com/s/categoryhome/Travelers"
                id="header_booking_help_link"
                rel="noopener noreferrer"
                target="_blank"
              >
                Need help?
              </ExternalLink>
            )}
          </div>
          {!navHidden ? (
            width >= fullNavWidth ? (
              <div className={style.navLinksContainer}>
                <div className={style.pagesContainer}>
                  <ul>
                    {headerMenu.main.map(({ name, title, url, dataTestId }) => (
                      <li key={name}>
                        <ExternalLink
                          className={`${style.headerLink} header_link btn-cta`}
                          data-testid={dataTestId}
                          href={url}
                          rel={
                            name === 'help' ? 'noopener noreferrer' : undefined
                          }
                          target={name === 'help' ? '_blank' : undefined}
                        >
                          {title}
                          {url === '/favorites' && showFavoritesBadge && (
                            <NotificationDot
                              className={style.linkFavoriteBadge}
                            />
                          )}
                        </ExternalLink>
                      </li>
                    ))}
                  </ul>
                </div>
                {/* Desktop Login/User */}
                {!session ? (
                  <div className={style.loginContainer} ref={dropdown}>
                    {/* Logged out dropdown */}
                    <button
                      className={`${style.headerLink} ${
                        dropdownActive
                          ? style.headerLinkTitleActive
                          : style.headerLinkTitle
                      } ${transparent ? style.transparent : ''}`}
                      data-testid="loginBtn"
                      onClick={() => setDropdownActive(!dropdownActive)}
                    >
                      Login
                    </button>
                    <div
                      className={`${
                        dropdownActive
                          ? style.menuDropdownActive
                          : style.menuDropdown
                      } ${transparent ? style.transparent : ''}
                `}
                    >
                      <div className={dropdownActive ? style.linkWrapper : ''}>
                        {guestLoginComponent}
                        {ownerLogin}
                      </div>
                    </div>
                  </div>
                ) : (
                  // Logged in user icon
                  <button
                    aria-label="account menu"
                    className={style.userMenuToggle}
                    onClick={() => handleUserMenuClick()}
                    ref={userToggleRef}
                  >
                    <Image
                      alt=""
                      className={style.userAvatarImg}
                      height={36}
                      src={session.user.image}
                      width={36}
                    />
                  </button>
                )}
                {userMenuActive && (
                  // Logged in user menu
                  <GuestHeaderMenu
                    guestHeaderMenuRef={guestHeaderMenuRef}
                    isMobile={false}
                    session={session}
                  >
                    {guestLoginComponent}
                    {ownerLogin}
                  </GuestHeaderMenu>
                )}
              </div>
            ) : (
              <div
                className={classNames(style.mobileMenu, {
                  [style.active]: mobileMenuActive,
                })}
              >
                {mobileMenuActive && (
                  <ul>
                    {headerMenu.main.map(({ title, url, name }) => (
                      <li key={name}>
                        <ExternalLink
                          className={`${style.headerLink} header_link btn-cta`}
                          href={url}
                          id={
                            name === 'help'
                              ? 'header_mobile_help_link'
                              : undefined
                          }
                          rel={
                            name === 'help' ? 'noopener noreferrer' : undefined
                          }
                          target={name === 'help' ? '_blank' : undefined}
                        >
                          {title}
                          {url === '/favorites' && showFavoritesBadge && (
                            <NotificationDot
                              className={style.linkFavoriteBadge}
                            />
                          )}
                        </ExternalLink>
                      </li>
                    ))}
                    {!session ? (
                      <>
                        <li>{guestLoginComponent}</li>
                        <li>{ownerLogin}</li>
                      </>
                    ) : (
                      <>
                        <li>
                          <GuestHeaderMenu isMobile session={session}>
                            {guestLoginComponent}
                          </GuestHeaderMenu>
                        </li>
                        <li>{ownerLogin}</li>
                      </>
                    )}
                  </ul>
                )}
              </div>
            )
          ) : null}
          {width < fullNavWidth && !step && !navHidden && (
            <button
              className={`${style.headerMobileHamburgerBtn} ${
                page === 'search' ? style.searchPage : ''
              }`}
              id="header_mobile_hamburger_btn"
              onClick={handleMobileMenuToggle}
              title={mobileMenuActive ? 'Hide main menu.' : 'View main menu.'}
            >
              <div
                className={`${style.mobileMenuTrigger} ${
                  mobileMenuActive ? style.mobileMenuTriggerActive : ''
                } ${transparent ? style.transparent : ''}`}
              >
                <span
                  className={transparent && !sticky ? style.transparent : ''}
                ></span>
                <span
                  className={transparent && !sticky ? style.transparent : ''}
                ></span>
                <span
                  className={transparent && !sticky ? style.transparent : ''}
                ></span>
                <span
                  className={transparent && !sticky ? style.transparent : ''}
                ></span>
                {!mobileMenuActive && showFavoritesBadge && (
                  <NotificationDot className={style.hamburgerFavoriteBadge} />
                )}
              </div>
            </button>
          )}
        </nav>
      </header>
    </>
  )
}

export default Header
