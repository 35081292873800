import React, { AnchorHTMLAttributes, DetailedHTMLProps } from 'react'
import { useRouter } from 'next/router'

import { preserveAndApplyQuery } from 'utils/Strings'

const ExternalLink: React.FC<
  DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>
> = ({ href, children, ...props }) => {
  const router = useRouter()
  return href ? (
    <a href={preserveAndApplyQuery(href, router.query)} {...props}>
      {children}
    </a>
  ) : (
    <a {...props}>{children}</a>
  )
}

export default ExternalLink
