// TODO remove `name` and use `url` as react key instead. Also replace custom logic based on `name='help'` with
// a configuration setting `openInTab: true` and `id?: string`
const headerMenu = {
  main: [
    {
      name: 'becomeAnOwner',
      title: 'Become an Owner',
      url: '/owner',
      dataTestId: 'becomeAnOwnerBtn',
    },
    { name: 'help', title: 'Help', url: '/contact', dataTestId: 'helpBtn' },
    {
      name: 'favorites',
      title: 'Favorites',
      url: '/favorites',
      dataTestId: 'favoritesBtn',
    },
  ],
}

export default headerMenu
