/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo } from 'react'
import Link, { LinkProps } from 'next/link'

import useAugmentedRouter from 'hooks/useAugmentedRouter'

import { applyPreservedQuery, preserveQuery } from 'utils/Strings'

type CustomLinkProps = LinkProps &
  Omit<
    React.DetailedHTMLProps<
      React.AnchorHTMLAttributes<HTMLAnchorElement>,
      HTMLAnchorElement
    >,
    'href'
  >

const CustomLink: React.FC<CustomLinkProps> = ({
  children,
  href,
  as,
  replace,
  scroll,
  shallow,
  passHref,
  prefetch,
  locale,
  id,
  ...props
}) => {
  const router = useAugmentedRouter()
  const preservedUtmHref = useMemo(() => {
    if (router.query && Object.keys(router.query).length) {
      const preservedQuery = preserveQuery(router.query)

      if (Object.keys(preservedQuery).length) {
        if (typeof href === 'string') {
          return applyPreservedQuery(href, preservedQuery)
        } else {
          return {
            ...href,
            query: {
              ...(href.query && typeof href.query !== 'string' && href.query),
              ...preservedQuery,
            },
          }
        }
      } else {
        return href
      }
    } else {
      return href
    }
  }, [href, router.query])

  return (
    <Link
      as={as}
      href={preservedUtmHref}
      legacyBehavior
      locale={locale}
      passHref={passHref}
      prefetch={prefetch}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
    >
      <a id={id} {...props}>
        {children ?? props.title}
      </a>
    </Link>
  )
}

export default CustomLink
