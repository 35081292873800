import React, { useCallback, useEffect } from 'react'
import { signIn, signOut, useSession } from 'next-auth/react'

import { useDispatch, useSelect } from 'store/index'

import {
  clearFavoritesState,
  setLoginComplete,
  setSaveAsAuth,
} from 'reducers/favorites'

import CustomLink from 'components/Link/CustomLink'

import { getSha256 } from 'utils/Strings'
import { pushToDataLayer } from 'utils/Gtm'

type GuestLoginProps = {
  id: string
  className: string
  displayText: { isLoggedIn: string; isLoggedOut: string }
  onLogout?: () => void
}

const GuestLogin: React.FC<GuestLoginProps> = ({
  id,
  className,
  displayText,
  onLogout,
}) => {
  const { data: session } = useSession()

  useEffect(() => {
    if (session?.user) {
      const pushSessionInfoToDataLayer = async () => {
        const email = session.user.email
        const splitName = session.user.name.split(' ')
        const firstName = splitName[0]
        const lastName = splitName[splitName.length - 1]

        const em = await getSha256(email.trim().toLowerCase())
        const fn = await getSha256(firstName.trim().toLowerCase())
        const ln = await getSha256(lastName.trim().toLowerCase())

        pushToDataLayer('customerInformation', {
          em,
          fn,
          ln,
        })
      }

      pushSessionInfoToDataLayer()
    }
  }, [session])

  // Redux actions
  const appDispatch = useDispatch()

  // Redux selectors
  const firstFavoriteId = useSelect((state) => state.favorites.firstFavoriteId)

  const handleGuestAuthClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      if (session) {
        // TODO: Handle session expiration
        onLogout?.()
        signOut({ redirect: false })
          .catch((err) => console.error(err))
          .finally(() => {
            appDispatch(clearFavoritesState())
            appDispatch(setLoginComplete(false))
            appDispatch(setSaveAsAuth(false))
            localStorage.removeItem('createAccountSuccessPushed')
            localStorage.removeItem('favorites')
          })
        window.open(process.env.NEXT_PUBLIC_SALESFORCE_OAUTH_LOGOUT_URL)
      } else {
        // Save first listing clicked to favorite it after login
        if (process.browser && firstFavoriteId) {
          localStorage.setItem('firstFavoriteId', firstFavoriteId)
        }
        signIn('salesforce').catch((err) => console.error(err))
      }
    },
    [onLogout, session, firstFavoriteId, appDispatch],
  )

  return (
    <CustomLink
      aria-labelledby={
        session ? 'log out of guest account' : 'log in to guest account'
      }
      className={className}
      href={session ? '/api/auth/signout' : '/api/auth/signin'}
      id={id}
      onClick={handleGuestAuthClick}
      prefetch={false}
      rel="noopener noreferrer"
    >
      {session ? displayText?.isLoggedIn : displayText?.isLoggedOut}
    </CustomLink>
  )
}

export default GuestLogin
